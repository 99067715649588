import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 8-8-8\\@75% 1RM`}</p>
    <p>{`Hamstring Curls 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`Tabata: (8 rounds of each, alternating exercises)`}</p>
    <p>{`Hang Power Clean (95/65)`}</p>
    <p>{`Burpee`}</p>
    <p>{`Front Squat (95/65)`}</p>
    <p>{`Score=total reps.`}</p>
    <p><em parentName="p">{`*`}{`compare to 6/8/17`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Sunday, May 20th.  For more
info email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      